var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 14 }}},[_c('div',{staticClass:"form-content"},[_c('a-form-model-item',{attrs:{"label":"提成比例","prop":"commission","rules":{
          required: true,
          trigger: ['blur', 'change'],
          message: '请输入提成比例',
          type: 'number',
          min: 0,
          max: 100,
        }}},[_c('a-input',{staticStyle:{"width":"420px"},attrs:{"addon-after":"%","type":"number","min":0,"max":100,"placeholder":"请输入提成比例"},model:{value:(_vm.form.commission),callback:function ($$v) {_vm.$set(_vm.form, "commission", _vm._n($$v))},expression:"form.commission"}}),_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',[_vm._v(" 此设置用于给销售计算业绩提成，业绩提成=销售额*提成比例 ")])]),_c('a-icon',{staticClass:"ml-3",attrs:{"type":"question-circle"}})],2)],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 4, offset: 22 }}},[_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.showModal}},[_vm._v(" 保存 ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }