<template>
  <div class="wrap">
    <a-form-model
      ref="form"
      :model="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
    >
      <div class="form-content">
        <a-form-model-item
          label="提成比例"
          prop="commission"
          :rules="{
            required: true,
            trigger: ['blur', 'change'],
            message: '请输入提成比例',
            type: 'number',
            min: 0,
            max: 100,
          }"
        >
          <a-input
            style="width: 420px"
            addon-after="%"
            v-model.number="form.commission"
            type="number"
            :min="0"
            :max="100"
            placeholder="请输入提成比例"
          />
          <a-tooltip placement="right">
            <template slot="title">
              <span>
                此设置用于给销售计算业绩提成，业绩提成=销售额*提成比例
              </span>
            </template>
            <a-icon class="ml-3" type="question-circle" />
          </a-tooltip>
        </a-form-model-item>
        <a-form-model-item
          :wrapper-col="{ span: 4, offset: 22 }"
          style="margin-bottom: 0"
        >
          <a-button type="primary" size="large" @click="showModal">
            保存
          </a-button>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { getStoreInfo, saveStoreInfo } from '@/api/store'

export default {
  name: 'Performance',
  components: {},
  data() {
    return {
      form: {
        commission: '',
      },
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$store.dispatch('loading/startContentLoading')
      getStoreInfo()
        .then((res) => {
          if (res.code === 0) {
            this.form = res.data
          }
          this.$store.dispatch('loading/endContentLoading')
        })
        .catch(() => this.$store.dispatch('loading/endContentLoading'))
    },
    showModal() {
      this.$confirm({
        title: '提示',
        content: '确定保存吗',
        onOk: (next) => {
          this.$message.loading({ content: '加载中', key: 'save' })
          this.save()
          next()
        },
      })
    },
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          const form = { commission: this.form.commission }
          saveStoreInfo(form).then((res) => {
            if (res.code === 0) {
              this.$message.success({ content: '保存成功', key: 'save' })
              this.getInfo()
              this.$store.dispatch('user/GetInfo')
            }
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="less">
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .form-content {
    padding: 25px 0 25px 50px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
</style>
